import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "../styles/ActionList.module.css";

function getDateBackgroundColor(data) {
  let nowDate = new Date().getTime();
  let deliveryDate = new Date(data.deliveryDate).getTime();
  let alertDate = new Date(data.alertDate).getTime();

  let className = `${styles.actionCard} px-2 py-3 my-3 clickable`;
  if (data.done) {
    return `${className} action-success-color-background`;
  } else if (nowDate < alertDate) {
    return `${className} action-warning-color-background`;
  } else if (nowDate >= alertDate && nowDate < deliveryDate) {
    return `${className} action-attention-color-background`;
  } else if (nowDate >= deliveryDate) {
    return `${className} action-danger-color-background`;
  }
}

function getFormattedDate(date) {
  if (date === null || date === undefined) {
    return "Não possui";
  } else {
    let cdDate = new Date(date);
    return cdDate.toLocaleDateString("pt-BR");
  }
}

function getFormattedTime(date) {
  if (date !== null || date !== undefined) {
    let cdDate = new Date(date);
    let hour = cdDate.getHours();
    let minutes = cdDate.getMinutes();
    let seconds = cdDate.getSeconds() === 0 ? "00" : cdDate.getSeconds();
    return hour + ":" + minutes + ":" + seconds;
  }
}

function getTaskDone(tasks) {
  let tasksDoneCount = 0;
  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].done) {
      tasksDoneCount++;
    }
  }

  return tasksDoneCount;
}

function getCurrentTask(tasks) {
  let currentTaskIndex;

  tasks.sort((a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    return 0;
  });

  for (let i = 0; i < tasks.length; i++) {
    if (!tasks[i].done) {
      currentTaskIndex = i;
      break;
    }
  }

  return currentTaskIndex === undefined
    ? ""
    : tasks[currentTaskIndex].taskType.name;
}

function RenderItem({ isDescription, label, value, colorClassName = "" }) {
  return (
    <Col
      xs={isDescription ? null : "6"}
      md={isDescription ? null : "4"}
      className="my-1 px-1"
    >
      {Boolean(value) ? (
        <p className="m-0">
          <span className={`font-weight-bold mr-2 ${colorClassName}`}>
            {label}
          </span>
          <span dangerouslySetInnerHTML={{__html: value}}></span>
        </p>
      ) : null}
    </Col>
  );
}

function ActionCard({ action, setShowModalInsertAction, title }) {
  return (
    <div
      className={getDateBackgroundColor(action)}
      onClick={() => setShowModalInsertAction(true, action)}
    >
      <Row>
        <Col xs="8">
          <h3 className={styles.actionTitle}>{title}</h3>
        </Col>

        <RenderItem
          colProps={{ xs: "4" }}
          label="Data/Hora de criação:"
          value={
            getFormattedDate(action.createDate) +
            " - " +
            getFormattedTime(action.createDate)
          }
          colorClassName="success-color"
        />
      </Row>
      <hr className="m-1" />

      <div className="d-none d-md-block ">
        <Row className="m-n1">
          <RenderItem label={action.tasks.length > 0 ? "Solicitado por:" : "Criado por:"} value={action.creator.name} />
          <RenderItem label="Agendado para:" value={action?.responsibleUser?.name} />
          <RenderItem label="Tarefa Atual:" value={getCurrentTask(action.tasks) === "" ? null : getCurrentTask(action.tasks)} />
        </Row>
        <Row className="m-n1">
          <RenderItem label="Tarefas:" value={action.tasks.length ? getTaskDone(action.tasks) + " / " + action.tasks.length : "N/A tarefas"} />
          <RenderItem label="Nível de acesso:" value={action.privaty ? "Interno" : "Externo"} />
          <RenderItem label="Data de alerta:" value={action.alertDate ? getFormattedDate(action.alertDate) : null} colorClassName="alert-color" />
        </Row>
        <Row className="m-n1">
          <RenderItem label="Documentos:" value={`${action.documents.length}`} />
          <Col xs="4" />
          <RenderItem label="Data de entrega:" value={action.deliveryDate ? getFormattedDate(action.deliveryDate) : null} colorClassName="deadline-color" />
        </Row>
      </div>
      <div className="d-block d-md-none ">
        <Row className="m-n1">
          <RenderItem label="Solicitado por:" value={action.creator.name} />
          <RenderItem label="Agendado para:" value={action?.responsibleUser?.name} />
        </Row>
        <Row className="m-n1">
          <RenderItem label="Tarefa Atual:" value={getCurrentTask(action.tasks) === "" ? null : getCurrentTask(action.tasks)} />
          <RenderItem label="Tarefas:" value={action.tasks.length ? getTaskDone(action.tasks) + " / " + action.tasks.length : "N/A tarefas"} />
        </Row>
        <Row className="m-n1">
          <RenderItem label="Nível de acesso:" value={action.privaty ? "Interno" : "Externo"} />
          <RenderItem label="Data de alerta:" value={action.alertDate ? getFormattedDate(action.alertDate) : null} colorClassName="alert-color" />
        </Row>
        <Row className="m-n1">
          <RenderItem label="Documentos:" value={`${action.documents.length}`} />
          <RenderItem label="Data de entrega:" value={action.deliveryDate ? getFormattedDate(action.deliveryDate) : null} colorClassName="deadline-color" />
        </Row>
      </div>
      <Row className="m-n1">
        <RenderItem isDescription label="Descrição:" value={action.additionalInformation} />
      </Row>
    </div>
  );
}

export default ActionCard;
